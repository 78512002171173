$primary:#15497c;
$secondary: #d8c593;
$borderColor: #93bed8;
// html{
//     font-size: calc(60% + 0.8vmin);
// }
*{
    font-size: 1rem;
    box-sizing: border-box;
}
h1{
    font-size: 2.2rem;
}
h2{
    font-size: 1.6rem;
}
h3{
    font-size: 1.3rem;
}
h4{
    font-size: 1.1rem;
}
body{
    width:100%;
    overflow-x:hidden;
//   font-size: calc(60% + 1vmin);  
    margin:0;
    padding: 0;
    // box-sizing:border-box;
    font-family: "oswald_regularregular",Arial,Verdana,Geneva,Helvetica,sans-serif;
    background-color: white;
    animation: fadeInOnLoad 3s ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    // page transition
    @keyframes fadeInOnLoad {
        0% {
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
        
    }
    & .top-margin{
        position: relative; 
        top :125px; 
        height: 123px;
    }
}
// Spinner


                .loader{
                width: 60px;
                height: 60px;
                color: orange;
                position: relative;
                top: 50%;
                left: 50%;
                margin: -25px 0 0 -25px;
                animation-name: spinner; 
                animation-duration:  2s; 
                animation-iteration-count: infinite;
                animation-timing-function:  ease-in-out;

                @keyframes spinner {
                    0%{
                        transform: rotate(0deg);
                    }
                    100%{
                        transform: rotate(360deg);
                    }
                    
                }
            }


// for the contact us page only


.nav-bar{
    display: block;
    // flex-direction: "row";
    // justify-content:flex-start;
   
    position:fixed;
    width: 100%;
    height: 122px;
    color: white;
    background-color: black;
    border-bottom: 15px solid orange;
    padding: 2px 5px;
    z-index: 999999;

     



    & .mobile-nav{
        position: absolute;
        display:none;
       
     
        .menu{
            width: 100px;
            height: 40px;
            padding: 5px 10px;
            color: $primary;
            font-size: 1em;
            font-weight: bold;
            border-radius: 6px;
        }
        .menu:hover{
            background-color: $primary;
            color: white;
        }
        .member{
            background-color: $primary;
            padding: 5px 2px;
            border-radius: 6px;
            height: 40px;
            width: auto;
            color: white;
            font-size: 1em;
            display: none;
        }
        .member:hover{
            background-color:  white;
            color: $primary;
        }
    }

   


   & .nav{
        position: relative;
        left: 15px;
        margin: 25px 1px 15px 0;
        width: auto;
        padding: 2% 0;
        list-style-type: none;
        transition: all 0,5s;
       
       

                   
        & li{
            float: left;
            margin: 0 auto;
        }
        & li a {
            text-decoration: none;
            vertical-align: bottom;
            padding: 2px 5px 5px 5px;
            margin-right: 1px;
            font-weight: bold;
            border-right: 0.5px solid gray;
            color: #4682ba;
            color: white;
            position: relative;
            text-transform: uppercase;
            transition: all 0.5s;
            font-family: "oswald_regularregular",Arial,Verdana,Geneva,Helvetica,sans-serif;
            font-size: 1.3vw;
            line-height: 1rem;
            
        }
        & li .submenu-container{
            display: none;
            
            // flex-direction: column;
            width: 250px;
            background-color:#b3d4f1;;
            position: absolute;
            margin-top: 6px;
            border-radius: 10px;
            
            text-decoration: none;
            text-align: left;
            transition: all 0.5s;
            

            & a{
                display: block;
                border-right: none;
                border-bottom: 1px solid black;
                border-radius: 0;
                padding: 8px 8px 5px 8px;
                color: gray;
                font-size: 1rem;
                // z-index: 10001;
            }
            a:hover{
                color: white;
            }
        }
        & a:hover{
            background-color: $primary;
            color: white;
            border-radius: 7px; 
        }
        
    }
    .logo{
        position: relative;
        width:20%;
        // height: 92px;
        margin-top: 2px;
        margin-left: 5px;
        padding: 5px;
        float: left;
        & img{
            width:100%;
            height:auto;
            max-width: 282px;
        }
    }
    .search-form {
       width: 35%;
       position: absolute;
       right: 25%;
       top:2px;
       z-index: 2;
        & input{
            float: left;
        }
    }

    
   & .search-field{
    width: 60%;
    float: left;
    padding: 0 20% 0 10px;
    min-height: 32px;
    max-height: 32px;
    color: #2c2c2c !important;
    
    border-radius: 0;
    // background: transparent;
    background: rgba(225,225,225,0.9);
    border: 0.1px solid gray
    }
    .search-btn{
        // width: 10%;
        float: left;
        width:32px; 
        height:32px;
        padding: 5px;
        background-color: transparent;
        position: relative;
        right: 30px;
        top:1px;
        
        border: none;
        border-radius: 0;

        .search{
            position: absolute;
            top:50%;
            left: 50%;
            margin: -15px 0 0 -25px;
            right: -18px;
            width:28px; 
            height:28px
            
         }

        }
      .nav-bar-top{
        position: absolute;
        right: 5%;
        top:4px;
        & a{
            text-decoration: none;
            vertical-align: bottom;
            padding: 8px 5px 5px 5px;
            margin-right: 1px;
            font-weight: bold;
            // color: #4682ba;
            color: white;
            position: relative;
            text-transform: uppercase;
            transition: all 0.5s;
            font-family: "oswald_regularregular",Arial,Verdana,Geneva,Helvetica,sans-serif;
            font-size: 1.1em;
            line-height: 1em;
            border-radius: 7px; 
        }
        & a:hover {
            background-color: $primary;
            color: orange;
        }
        
      } 
     & #about_ssa:hover #sub_about_ssa{
        // display: flex;
        display: block;
    }
    & #membership:hover #sub_membership{
        // display: flex;
        display: block;
    }
    & #events:hover #sub_events{
        // display: flex;
        display: block;
    }
    & #publications:hover #sub_publications{
        // display: flex;
        display: block;
    }
    & #resources:hover #sub_resources{
        // display: flex;
        display: block;
    }
    & #news:hover #sub_news{
        // display: flex;
        display: block;
    }
    
}

.slider-container{
    width: 100%;
    background-color: gray;
    // border-bottom: 15px solid $borderColor;
    border-bottom: 15px solid orange;
    overflow:hidden;
    position: relative;
    padding: 0;
    margin: 0;

    & .slider{
        width:100%;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        // background-image: url('/src/img/wprwestafrica1.jpg');
        // background-repeat: no-repeat;
        // background-size: contain;
        
       
       
        // margin:10px 0 0 0;
        // object-fit: cover;
        // object-position: bottom;
        transition: all 2s;
        // opacity: 1;
        & img{
            width: 100%;
        }
        
    }
    .fade{
        opacity: 0;
    }

    & .btn-container{
        position: absolute;
        top:50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:100%;
        padding: 0 40px 0 30px;
        z-index: 500;
       
        & .btn{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: rgba(0,0,0, 0.5);

            & .arrow{
                margin-top: -5px;
                margin-left: -5px;
                width: 30px;
                height: 30px;
               color: white;
               border:none
            }
        }
    }
}
.social-bar{
    width: 100%;
    // height: 530px;
    height:auto;
    background-image: url('/src/img/back-image-socialoriginal.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    // background-color: rgba(0,0,0, 0.8);
   
    // padding: 50px 0 50px 0px;
    & .social-bar-cover{
        width: 100%;
        background-color: rgba(0,0,0,0.7);
                
            
                & .social-bar-container {
                    background-color: rgba(0,0,0,.4);
                    height: auto;
                    width: 100%;
                    & div{
                        float: left;
                        width: 25%;
                        // height: 450px;
                        padding: 10px 2px;
                        // margin-right: 1px;
                        // overflow: auto;
                        // text-align: center;
                        background-color: rgba(0,0,0,0.2);
                        // background-color: green;
                        height: 530px;

                        & div{
                             width: 100%;
                             margin-top: 2px;
                            & h4{
                              
                                padding: 2px 10px 2px 2px;
                                margin:10px 20px; 
                                color: white;
                                
                            }
                            & p{
                               
                                background-color: rgba(255,255,255,0.5);
                                width:90%;
                                height: 80%;
                                padding: 1px 1px;
                                margin:0 auto ;
                                transition: all 1s;
                                color: white;

                                &:hover{
                                    box-shadow: 2px 2px 2px 2px orange;
                                }
                            
                            }
                        }
                        

                    }


                }
                
                // & div div div{
                //     border-right: 1px solid black;
                //     margin:40px auto; 
                //     width: 100%;
                    
                // }
                
                
                .social-banner{
                    border-right:none;
                    background-color: $primary;
                    height: 530px;
                    width: 25%;
                    
                    
                    // padding:0 ;
                    // margin-left:2px;
                    overflow: hidden;
                    & .social-banner-container{
                        width: 90%;
                        height: 420px;
                        list-style-type: none;
                        margin: 10px 5%;
                        padding: 10px;
                        box-shadow: 2px 5px 2px 5px gray;
                        background-color: white;
                        overflow-y:auto;
                        border-radius: 25px 0 25px 0;
                        & li{
                            display: flex;
                            flex-direction: row;
                            justify-content:space-between;
                        padding: 5px 10px 5px 30px;
                            margin: 10px 2px;
                            border-bottom: 1px dotted gray;
                        
            
                            & img{
                                width:60px;
                                height:60px;
                                border-radius:50% ;
                            }
                            & p{
                                float: right;
            
                            }
                        }
                    }
                }
                & .social-banner{
                    position: relative;
                    & h2{
                        color: white;
                        font-weight: bold;
                        &:hover{
                            color: orange;
                        }
                    }
            
                    & .loader{
                        width: 60px;
                        height: 60px;
                        color: orange;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin: -25px 0 0 -25px;
                        animation-name: spinner; 
                        animation-duration:  2s; 
                        animation-iteration-count: infinite;
                        animation-timing-function:  ease-in-out;
            
                        @keyframes spinner {
                            0%{
                                transform: rotate(0deg);
                            }
                            100%{
                                transform: rotate(360deg);
                            }
                            
                        }
                    }
                }




        // social-bar cover ends
    }

}
.adverts{
    position: relative;
    width: 100%;
    // height: auto;
    background-color: black;
    padding: 30px 5px 5px 30px;
    text-align: left;
    display: block;
    overflow: hidden;
    // margin: auto;
   

   & div{
    position: relative;
    top: 25%;
   left: 10px;
   width:100%;
   margin-right: 2px;
   padding: 20px;
    float: left;
    width:29%;
   }
   & .benefits{
    color: white;

   }
   & .logo-advert{
    margin-top: 50px;
   }
   & .be-a-member{
    margin-top: 50px;
   }
   &  .logo-advert:hover{
    transition: all 2s;
    transform: scale(1.1,1.1);
}
}
.aboutus-container{
    // background-color: $secondary;
    background-color: white;
    padding: 5px 10px 60px 5px;
    margin: 0;
    text-align: center;
    width: 100%;
    & .about-us{
        margin: 20px 24px;
        text-align: left;
        font-size: 1.4rem;
        
    }
    & .btn-about a{
        margin: 10px;
        width: 100px;
        height: 50px;
        border-radius: 10px;
        text-decoration: none;
        background-color: $primary;
        color: white;
        font-size: 15px;
        transition: all 1s;
    }
    & .btn-about:hover{
        transform: translate(-5px,-5px);
        background-color: #93bed8;
    }
}
.hide-content{
    opacity: 0;
 }
.show-content{
    transform: translateY(1rem);
    transition: all 3s;
    opacity: 1;
 }
 .carosel{
    position: relative;
    
    background-color: #f0f0f0;
    height: auto;
    width: 100%;
    margin:  0 auto 20px ;
    position: relative;
    background-image: url('/src/img/dubai2022.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    border: none;
    
    & .carosel-inner-container{
        position:relative;
        // top: 4px;
        width: 100%;
        // height: 350px;
        
        // background-image: url('/src/img/dubai2022.jpg');
        background-color: rgba(0,0,0,0.7);
        overflow: hidden;
        & .content-container {
                
                position: relative;
                width: 100%;
                // padding: 10px 0 ;
                transition: all 2s;
                margin: 0 auto;
                display: flexbox;
                align-items: center;
                justify-content: center;
                font-size: auto;
               
           & .carosel-content{
                        position: absolute;
                        // left: 10%;
                        // right: 10%;
                        // top: -40px;
                        z-index: 9999;
                        width:100%;
                        font-size: 5vw;
                        // height: auto;
                        
                        background-color:rgba(0,0,0,0.1);
                        padding: 5px 10px;
                        // margin: 10px 150px;
                        transition: all 2s;
                        & h1{
                            
                            text-align: center;
                            color: white;
                        }
                        
                        & p{
                            margin: 10px auto;
                            text-align: center;
                            // margin: 0 auto;
                            color: white;
                            font-size: 1.5rem;
                        }
                        & .btn a{
                            color: white;
                            text-decoration: none;
                            font: inherit
                            
                        }
                    }

        } 
        
        
        
        
        & .carosel-btn-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            width: 100%;

            & .btn{
                margin: 145px 2px ;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: rgba(0,0,0, 0.2);
                border-color: alpha($color: #000000);
                color: white;
                font-size: 2rem;
                position: relative;
                z-index: 99999;
            }
        }
    }
    // & .dot-container{
    //     text-align: center;
    //     padding: 10px;
       
    //     & .dot-btn{
    //         width:15px;
    //         height: 15px;
    //         color: gray;
    //         border-radius: 50%;
            
    //     }
    // }
 }

 .btn{
    // margin: 10px;
    width: auto;
    height: 50px;
    border-radius: 5px;
    background-color: $primary;
    color: white;
    font-size: 15px;
    transition: all 1s;
    padding: 5px;
    &:hover{
        transform: translate(-5px,-5px);
        background-color: #93bed8;
    }
}
.content-banner{
    
    width:100%;
    height:100px;
    margin-top: 20px;
    background-color: black;
    padding: 3px;
    & h1{
        color: white;
        text-align: center;
        margin: 10px;
        
    }
}
.banner-arrow{
    margin-left: 49%;
    margin-top: -2px;
}
.content-container{
    display: block;
    background-color: white;
    margin: 40px 20px 20px 20px;
    & .side-bar-container{
        width: 23%;
       height: 400px;
       float: left;
       background-color: $secondary;
       padding: 20px;
        }
    & .main-content-container{
        width:65%;
        margin: 2px 10px 3px 40px;
        height: auto;
        padding: 0 20px;
        float: left;
        & p{
            font-size: 1.4em;
            line-height: 1.3em;
            // text-align:justify;
        }
        & ul li{
            font-size: 1.5em;
            line-height: 1.3em;
        }
       & .list-of-events{
            list-style-type: none;
            margin-left: 0;
            padding: 0;
           
            & li{
                // background-color: gray;
                border-radius: 20px 0 20px 0;
                padding: 4px 5px;
                border-bottom: 1px dotted black;
                transition: all 2s;
            &:hover{
                box-shadow: -5px 5px darkblue;
                color:$secondary;
            }
            
            }
            
        }
        & .table-fees{
            border: 1px solid black;
            margin: 5px;
            font-size: 1.5rem;
            & th{
                border: 1px solid black;
                padding: 10px;
            }
            & tr td{
                border: 1px solid black;
                padding: 10px;
            }
        }
        & .contact-address-container{
            display: block;
            & .form-content{
                float: left;
                width: 50%;
                background-color: #ececec;
                overflow: auto;
                & form{
                    margin: 10px;
                }
            }

            & .contact-address{
                float: left;
                width: 45%;
                margin-left: 15px;
                padding:20px;
                // background-color: orange;
                & .social-media-icon img{
                    width: 32px;
                    height: 32px;
                    margin: 3px;
                }
            }
        }
    }
}
.reps-container{
    display: block;
    & .reps{
        float: left;
        margin-right: 60px;
    }
}

.page-content{
    width: 80%;
    background-color: white;
}
.side-bar-inner{
    width:100%;
    height: 100%;
    background-color: white;
    padding: 5px 1px;
    overflow: auto;
    & ul{
        list-style-type: none;
        padding: 5px;
        margin:5px;
       & li{
        line-height: 1.3em;
        border-bottom: 1px dotted gray;
        padding: 2px 3px;
        margin: 2px;
        &:hover{
            background-color: $primary;
            color: white;
        }
        & a{
            text-decoration: none;
            &:hover{
                color: white;
            }
        }
       }
    }
}
.footer-content-inner{
    list-style-type: none;
   & li a{
        color: white;
        line-height: 2em;
        text-decoration: none;
        font-size: 0.9em;
    }
    & li a:hover{
        color: $secondary;
    }
}
 .footer{
    position: relative;
    width: 100%;
    background-color: black;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% auto;
    grid-gap:3px 10px;

    & .contactus-footer{
        position: inherit;
         grid-area: 1/7,span 2/8;
        background-color: $primary;
        padding: 5px 10px 5px 6px;
        & .search-form-footer {
            position: relative;
            width: 80%;
            margin: 10px 10% 1px 10%;
            & .search-field-footer{
             width: 80%;
             float: left;
             padding: 0 20% 0 10px;
             min-height: 32px;
             max-height: 32px;
             color: white !important;
             font-size: 1em;
             border-radius: 0;
             background: rgba(0,0,0,.3);
             border: 0.1px solid gray;
             transition: all 2s;
             }
             & .search-btn-footer{
                 position: inherit;
                width: 80%;
                float: left;
                min-height: 52px;
                max-height: 32px;
                padding: 5px;
                background-color: transparent;
                top:-5px;
                border: none;
                border-radius: 0;
                transition: all 2s;
        
                & .search-footer{
                    float: left;
                   position: absolute;
                   top:-25px;
                   right: 10px;
                    width:28px; 
                    height:28px;
                    transition: all 2s;
                 }
        
                }
             
         }
        & .contact-footer{
            
            & .cf{
                position: inherit;
                width: fit-content;
                & a{
                    color: white;
                    text-decoration: none;
                    background-color: orange;
                    padding: 5px;
                    margin: 0 5px 10px 5px;
                    border-radius: 15px 0 15px 0;
                    font-size: 1.5vw;
                    &:hover{
                        transform: translatex(2px);
                        color: black;
                            }
                    }
                   }
                & p {
                    color: white;
                    margin: 20px 10px;
                    }
                & a:hover{
                    text-decoration: underline;
                    
                 }
        }
                & .social-media{
                    transition: all 1s;
                    & a img:hover{
                        transform: translate(-5px,-5px);
                        background-color: orange;
                    }
                }
       
       
     
                & .contact-address{
                    color: white;
                    margin: -20px 10%;

                    & span{
                        font-weight: bold;
                        font-size: 0.9em;
                        padding-bottom: 2px;
                    }
                    
                }
                & .social-media{
                    margin: 10px 10%;
                    text-align: center;
                    &  a img {
                        background-color: white;
                        width:32px;
                        height: 32px;
                        float: left;
                        margin: 8px;
                        padding: 5px;
                        
                        transition: all 2s;
                    }
                
                }
    
    }
  
 }
 .copyright{
    position: relative;
    // bottom: 1px;
    top: 2px;
    padding: 5px 15px;
    color: gray;
    font-size: 75%;
    background-color: black;
    &:hover{
        color: orange;
       }
    & p{
        font-size: 1.1rem;
        text-align: center;
    }
   }
    


 .heading{
    display: block;
    padding: 10px 0 5px 10;
    position: relative;
    border-bottom: 1px solid #fff;
    text-decoration: none;
    color: #fff;
    position: relative;
    text-transform: uppercase;
    transition: all 0.5s;
    font-family: "oswaldlight",Arial,Verdana,Geneva,Helvetica,sans-serif;
    font-size: 1.2em;
    line-height: 1em;
    &:hover{
        color: orange;
    }
    & .change-arrow{
        color:white; 
        font-size: 0.8rem; 
        position: absolute; 
        right: 40px;
        // z-index: -10;
        visibility: hidden;
    }
}

// .footer-content{
//     // margin: 40px 5px 5px 5px;
//     // width:14.5%;
//     // float:left;
//     // display:block;
   
// }


 .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

 
 @media screen and (min-width:320px) and (max-width:768px){
   
    h1{
        font-size: 4vw;
    }
    .side-bar-container{
    display: none;
   }
   .aboutus-container{
    width: 100%;
    padding: 1px 2vw 1 3vw;
     & .btn-about{
        width: 18%;
        & a{
            font-size: 100%;
            margin: auto;
            text-align: center;
        }
     }
   }
   .content-container .main-content-container{
    width: 90%;
    margin:1px 20px;
    padding:5px;

    & .contact-address-container{
        display: block;
        & .form-content{
           width: 100%;
            & form{
                margin: 10px;
            }
        }

        & .contact-address{
            width: 100%;
            // background-color: orange;
            & .social-media-icon img{
                width: 32px;
                height: 32px;
                margin: 3px;
            }
        }
    }
   }
    .footer-content {

       width: 90%;
       margin:0 5%;
       height: auto;
       content: '';
       clear: both;
       text-align: center;
       
    } 
    .footer-content-inner{
        text-align: left;
        margin-left: 20%;
        font-size: 1.1em;
        height: 0;
        overflow: hidden;
        transition: all 1s;
        & li a:hover{
        color: $borderColor;
        }
    }
    .show-inner{
        text-align: left;
        margin-left: 20%;
        font-size: 1.1em;

        height: auto;
        overflow: hidden;
        transition: all 2s;
        & li a:hover{
        color: $borderColor;
        }
    }
    .heading{
        display: block;
        padding: 10px 0 5px 10;
        position: relative;
        border-bottom: 1px solid #fff;
        text-decoration: none;
        color: #fff;
        position: relative;
        text-transform: uppercase;
        transition: all 0.5s;
        font-family: "oswaldlight",Arial,Verdana,Geneva,Helvetica,sans-serif;
        font-size: 1.2em;
        line-height: 1em;
        &:hover{
            color: orange;
        }
        & .change-arrow{
            color:white; 
            font-size: 0.8rem; 
            position: absolute; 
            right: 40px;
            // z-index: -10;
            visibility: visible;
        }
    }
    
    .adverts{
       overflow: hidden;
      
      display: flex;
      flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
      & .logo-advert {
        width: 80%;
        margin: 10px 10%;
        padding: 0;
        & img{
            width: 80%;
            margin: 10px 0;
        //   margin: 0 auto;
        }
    }

    &  div{
        position: relative;
        top: 0px;
        left: 0;
        width:100%;
        padding: 0 ;
        margin: 10px 0;
        // border-bottom: 1px solid white;
       & h2{
        text-align: center;
       }
        
      }
      & .benefits ul li{
        padding-right: 20px;
      }
    }

    
    .footer{ 
        display: block;
        
        & .contactus-footer{
            height: 320px;
            & .contact-footer{
                position: relative;
                margin:5px 20px 5px 30px;
                display: block;
                // top: 40px;
                & .cf{
                    & a{
                        color: white;
                        text-decoration: none;
                        background-color:orange;
                        padding: 5px;
                        margin: 0 5px 5px 5px;
                        border-radius: 15px 0 15px 0;
                        font-size: 4vw;
                    }
                  
                } 

                & p{
                    color: white;
                  }


            }
            
        }
    & .footer-content-inner{
       width: 90%;
       margin: 0 5%;
    text-align: center;

       
    }
        
    }

    .copyright{
        position: relative;
        padding: 5px 10px;
        & p{
            font-size: 2.2vw; 
            // font-weight: bold;
        color: gray;  
        }  
    }
    
    
    .social-bar{
       & .social-bar-cover{

        & .social-bar-container {
            background-color: rgba(0,0,0,.4);
            height: auto;
            width: 100%;
            & div{
                float: left;
                width: 100%;
                // height: 450px;
                padding: 10px 2px;
                // margin-right: 1px;
                // overflow: auto;
                // text-align: center;
                background-color: rgba(0,0,0,0.2);
                // background-color: green;
                height: 400px;

                & div{
                     width: 100%;
                     margin-top: 2px;
                    & h4{
                      
                        padding: 2px 10px 2px 2px;
                        margin:10px 20px; 
                        color: white;
                        text-align: center;
                        
                    }
                    & p{
                       
                        background-color: rgba(255,255,255,0.5);
                        width:70%;
                        height: 80%;
                        padding: 1px 1px;
                        margin:0 auto ;
                        transition: all 1s;
                        color: red;

                        &:hover{
                            box-shadow: 2px 2px 2px 2px orange;
                        }
                    
                    }
                }
                

            }


        }
       }
        
        
    }
.social-banner{
    display: none;
}
    
    .nav-bar-top{
        visibility: hidden;
    }
    .search-form{
        visibility: hidden;
    }
    .logo{
        position: absolute;
        top:10px;
        left: 5%;
        width:auto;
        min-width: 180px;
      

        & img{
            width: auto;
          
            
        }
    }
   .nav-bar .mobile-nav{
        position: absolute;
        right: 10%;
        top:20%;
        
        display: block;
        & .menu{
             background-color: gray;
             width:100%;
             
        }
       
    }
    .nav-bar .nav  {
       
        position: relative;
        top:50px;
        left: 0;
        margin: 0;
        width: 100%;
        background-color: rgba(0,0,0, 0.7);
        height: 100vw;
        visibility: hidden;
        color: white; 
        display: flex;
        flex-direction: column;
    & li {
        color: white;
        margin: 10px 15px;
        
       
        
        & a{
            color: white;
            border-right: none;
            font-size: 1rem;
            
            
        }
    }

      & li div{
        position: relative;
       margin-left: 35%;
       top: 10px;
        border-radius: 0;
        height: 50%;
       
      }
    }
    
    .carosel{
        width: 100%;
        height: auto;
        & .carosel-inner-container {
            & .content-container {
                & .carosel-content{
                    margin: 9vh auto;
                    & h1{
                        font-size: 4vw;
                       
                    }
                    & p{
                       
                        font-size: 3vw;
                    }
                    & .bt{
                        width: 20%;
                        & a{
                            font-size: 100%;
                        }
                    }
                }
            }
        }
   }
   

  }

 .nav-bar .showmenu{
    visibility: visible;
   
  }
 .nav-bar .hide{
    
    visibility: hidden;
 

   
        // position: absolute;
        // left: 10%;
        // right: 10%;
        
        // top: -40px;
        
    
    // & .carosel-btn-container{

    //     width: 100%;
    //     & .
    // }
    // .slider-container .slider img{
    //     width: 100%;
    // }
  }
  
  @media screen  and (min-width:769px)and (max-width:1450px){
    .publications-footer{
        display: none;
    }
    .resources-footer{
        // margin-top: -193px;
        // margin-bottom: 75px;
        display: none;
    }
    .footer-content{
        width:100%;
    }

    .footer .contactus-footer{
    
      width:110%;
    }
    

    // & .cont {
    //     position: absolute;
    //     top: 70px;
    // }
    // & .cont1{
    //     position: absolute;
    //     bottom: 80px;
    // }
    // & .cont2{
    //     position: absolute;
    //     bottom: 5px;
    // }
    // }
    .footer{
        display: grid;
        grid-template-columns: 25% 25% 20% auto;
        // grid-template-rows: auto auto;
        & .contactus-footer{
            grid-area: 1/2, span 2/3;
            // background-color: orange;
            & .contact-footer{
                width: 100%;
                & .cf{
                    position: relative;
                    
                  
                    & a{
                    position: relative;
                    top:-30px;
                     float:none;  
                    }
                }
                & p{
                    color: white;
                    margin: 15px 20px;
                }
            }
        }
    }
   .nav-bar {
   
   & .nav {
        margin-left: 10px;
    //    & li a{
    //     // font-size: 73%;
    //     // padding: 20px;
        
    //    }
    }

    & .logo{
        width:20%;
        min-width: 200px;
       
        margin-top: 10px;
        margin-left: 5px;
    }
    // & .nav-bar-top{
    //     position: absolute;
    //     right: 5px;
    //     top:0px;
    //   }

    // & .search-form {
    // right: 30px;
    // top:0;
    
    // }

   }


   .social-bar{

    & div div{
        width: 25%;
        // height: 50%;
       
        & div div h4{
          
            margin:0;
            
        }
    }
}

.adverts{
    width: 100%;
    padding: 20px 10px;
    // height: 30%;
    & .be-a-member{
        display: none;
        
    }
    & div {
        width:45%;
         padding: 0 10px;
        
    }
    & .logo-advert{
        margin-top: 55px;
    }
}
.content-container{

    & .side-bar-container{
        width:25%;
        height: auto;
    }
    & .main-content-container{
        width: 55%;

        & .contact-address-container{
            .form-content{
                width:50%;
                margin-right: 20px;
        
            }
            .contact-address{
                width:40%;
                // margin-right: 20px;
                padding:5px 20px 5px 5px ;
                font-size: 95%;
            }
        }
    }
    & .contact-side-bar{
        display:none;
    }
    & .contact-main{
        width:100%;
    }
        
}
.content-banner{
    
    & h1{
        // font-size: 150%;
        padding: 5px 10px;
    }
}
.contact-resource{
    margin-top: -150px;
}
.contact-event{
    margin-top: 70px;
}
.nav-bar.contact-nav{
    margin-top: -20px;
}
.nav-bar .contact-logo{
    margin-top: -5;
}
.contact-banner{
    & h1{
        margin-top: 30px;
    }
}

}

// @media screen and (min-width: 1025px) and (max-width: 1400px){
//     .publications-footer{
//         display: none;
//     }
//     .resources-footer{
//         margin-top: -193px;
//         margin-bottom: 75px;
//     }
//     .footer-content{
//         width:45%;
//     }
//     .footer .contactus-footer{
//       position: absolute;
//       min-width:22.7%;
//     //   height: 520px;
    

//     & .cont {
//         position: relative;
//         top: -115px;
//     }
//     & .cont1{
//         position: relative;
//         bottom: 80px;
//     }
//     & .cont2{
//         position: relative;
//         bottom: 5px;
//     }
//     }
    
// }